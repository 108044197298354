const modal = () => {
  $(document).on("modal:open", (event, modal) => {
    $("body").css("overflow", "hidden");
  });

  $(document).on("modal:close", () => {
    $("body").css("overflow", "");
  });

  let modal = $('.modal-open');

  modal.on('click', function(event) {
    event.preventDefault();
    $(this).modal({
      fadeDuration: 300,
      fadeDelay: 0.50,
      blockerClass: "modal-overlay",
      closeClass: "modal-close"
    });
  });

  $(document).on("modal:after-close", (event, modal) => {
    $(".modal-contact-form").removeClass("modal__content-hidden");
    $(".modal__succes").addClass("modal__content-hidden");
  });
}

export default modal;