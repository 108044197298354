const form = (id, url) => {
  const currentForm = $(`#${id}`);

  currentForm.on("submit", (e) => {
    e.preventDefault();

    if (validateForm(currentForm)) {
      const formData = currentForm.serialize();

      const requestBinUrl = url;
  
      $.post(requestBinUrl, formData)
        .done(function() {
          if (id === "form-task" || id === "form-presentation-product") {
            $(".modal-contact-form").addClass("modal__content-hidden");
            $(".modal__succes").removeClass("modal__content-hidden");
          }

          currentForm.find(".btn--submit").text("Отправлено :)");
        })
        .fail(function() {
          $("#modal_error").modal({
            fadeDuration: 300,
            fadeDelay: 0.50,
            blockerClass: "modal-overlay",
            closeClass: "modal-close"
          });
        })
        .always(function() {
          clearForm(currentForm);
        });
    }
  });

  const validateForm = (form) => {
    let isValid = true;

    form.find(".input__input:not([type='hidden']), .checkbox .checkbox__required").each(function() {
      if ($(this).is(":checkbox")) {
        if (!$(this).prop("checked")) {
          isValid = false;
          $(this).closest(".checkbox").addClass("checkbox--error");
        } else {
          $(this).closest(".checkbox").removeClass("checkbox--error");
        }

      } else if (!$(this).val().trim()) {
        isValid = false;
        $(this).closest(".input").addClass("input--error");

      } else {
        $(this).closest(".input").removeClass("input--error");
      }
    });

    return isValid;
  };

  const clearForm = (form) => {
    form.find(".input__input, .checkbox__checkbox").val("");
    form.find(".textarea__area").val("");
    form.find(".input").removeClass("input--error");
    form.find(".checkbox").removeClass("checkbox--error");
  };
};

export default form;