import form from "./form/form";
import input from "../blocks-ui/input/input";
import cookie from "./cookie/cookie";
import footer from "./footer/footer";
import menu from "./menu/menu";
import modal from "./modal/modal";
import teamSlider from "./team/team";
import topTop from "./top-top/top-top";
import  { progres  } from "./progress/progress";


window.addEventListener("DOMContentLoaded", () => {
  AOS.init({
    disable: 'mobile'
  });
  if ($('.cookie').length) {
    cookie();
	}
	if ($('.menu--main').length) {
		menu();
	}
	if ($('.input--phone').length) {
		input();
	}
	if ($('.modal-open').length) {
		modal();
	}
  if ($('.team__slider').length) {
		teamSlider();
	}
  if ($('.progress').length) {
		progres();
	}
  if ($('.top-top').length) {
		topTop();
	}
  if ($('.form').length) {
		form("main-yourchallenges", "/mailer.php");
		form("form-task", "/mailer.php");
		form("form-presentation-product", "/mailer.php");
		form("form-presentation-video-mybeam", "/mailer.php");
		form("form-presentation-video-myservice", "/mailer.php");
	}
  footer();
});
