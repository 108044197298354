const teamSlider = () => {
  const prevButton = $("#prevButton");
  const nextButton = $("#nextButton");
  const team_slider = $('.team__slider').slick({
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    arrows: false,
  });


  team_slider.on("afterChange", function(event, slick, currentSlide) {
    prevButton.prop("disabled", currentSlide === 0);

    // nextButton.prop("disabled", currentSlide === slick.slideCount  - 1);
  })

  prevButton.on("click", function() {
    team_slider.slick("slickPrev");
  })
  nextButton.on("click", function() {
    team_slider.slick("slickNext");
  })
}


export default teamSlider;