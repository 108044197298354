const input = () => {
  $('.input--phone .input__input').mask('+7 (000) 000-00-00');
  
  $(".input--name .input__input").on("input", function(){
    if(/[^a-zA-ZА-Яа-яЁё\s\-]/.test($(this).val())){
        let Selection = this.selectionStart-1;

        $(this).val( $(this).val().replace(/[^a-zA-ZА-Яа-яЁё\s\-]/g, '') );

        this.setSelectionRange(Selection, Selection)
    }
  });
}

export default input;
